import React from 'react';
import normalize from 'json-api-normalizer';
import Grid from '@mui/material/Grid';

import { usePageState, useDataFetchOnLoad } from './Hooks';
import { axiosInstance } from '@/helpers/api_helper';
import { VerbClozeCard } from '@/components/Practice/Cards';
import { NoQuestionsAvailable } from '@/components/Practice/Cards/Common';
import { AuthContext } from '@/containers/AuthProvider';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { cookieConsentCookieExists } from '@/containers/CookieConsent';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

const dataUrl = '/api/v1/practice/sentence_conjugations';

const Wrapper = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

const VerbClozePage = () => {
  const { user, isAuthenticated, setUser } = React.useContext(AuthContext);
  const [pageState, dispatch] = usePageState();
  const { fetchData, normalizedData, loaded, loading, showSkeleton } = useDataFetchOnLoad(dataUrl);
  const noQuestions = _isEmpty(normalizedData);
  const { pathname } = useLocation();

  let sentence = {};
  let verbCloze = {};
  let conjugation = {};
  let infinitive = {};

  if (loaded && !noQuestions) {
    verbCloze = Object.values(normalizedData.sentenceConjugation)[0].attributes;
    sentence = Object.values(normalizedData.sentence)[0].attributes;
    infinitive = Object.values(normalizedData.infinitive)[0].attributes;
    conjugation = Object.values(normalizedData.conjugation)[0].attributes;
  }

  function submitPracticeData(evt) {
    const { answer } = pageState;
    evt.preventDefault();

    axiosInstance.post(`/api/v1/sentence_conjugations/${verbCloze.id}/answer`, { answer, user_id: user.id })
      .then((response) => {
        dispatch({ type: 'correctAnswer' });
        fetchData().then(() => {
          if (isAuthenticated || cookieConsentCookieExists()) {
            ReactGA.event({
              category: 'Verb Cloze', action: 'correct_answer', label: `Verb Cloze ${verbCloze.id}`, value: answer
            });
          }

          dispatch({ type: 'resetAnswer' });
        });
      }).catch((error) => {
        if (error.response.status !== 401) {
          const responseData = normalize(error.response.data);
          const responseConjugation = Object.values(responseData.conjugation)[0].attributes;

          if (isAuthenticated || cookieConsentCookieExists()) {
            ReactGA.event({
              category: 'Verb Cloze', action: 'incorrect_answer', label: `Verb Cloze ${verbCloze.id}`, value: answer
            });
          }

          dispatch({ type: 'incorrectAnswer', answer: responseConjugation.name });
        }
      }).finally(() => {
        // Set this here to avoids refetching the user meta from the frontend
        if (isAuthenticated) {
          setUser({ ...user, last_question_type_seen: 'SentenceConjugation' });
        }
      });
  }

  function fetchNext() {
    fetchData().then(() => {
      dispatch({ type: 'pageClear' });

      if (isAuthenticated || cookieConsentCookieExists()) {
        ReactGA.send({ hitType: 'pageview', page: pathname });
      }
    });
  }

  function handleKeyPress(evt) {
    // If the user types enter to clear the page, trigger a data reload and reset the initial state and fetch new data.
    if (evt.key === 'Enter') {
      if (pageState.practiceDisabled) {
        fetchNext();
      } else {
        submitPracticeData(evt);
      }
    }
  }

  React.useEffect(() => {
    // If the user has just typed an error, and we're showing what the correct answer should have been
    // add an event handler to allow the user to type enter to clear the page
    window.addEventListener('keypress', handleKeyPress, false);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [pageState.practiceDisabled, verbCloze, pageState.answer]);

  const showNoQuestionView = noQuestions && loaded;

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Conjugations</title>
        <meta name="description" content="Verb Conjugation Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Verb, Conjugation, Cloze, FlashCard" />
      </Helmet>

      <Wrapper container justifyContent="center">
        {showNoQuestionView && <NoQuestionsAvailable redirectTo="/verb-cloze" />}
        {!showNoQuestionView && (
          <VerbClozeCard
            showSkeleton={showSkeleton}
            showSuccess={!!pageState.lastResponseStatus}
            showFailure={pageState.lastResponseStatus === false}
            disabled={pageState.practiceDisabled}
            handleSubmit={submitPracticeData}
            sentence={sentence}
            infinitive={infinitive}
            conjugation={conjugation}
            verbCloze={verbCloze}
            formData={pageState.answer}
            fetchNext={fetchNext}
            setFormData={(newValue) => dispatch({ type: 'updateAnswer', answer: newValue })}
            loading={loading}
          />
        )}
      </Wrapper>
    </>
  )
}

export default VerbClozePage;
