import styled from 'styled-components';

const StyledAppWrapper = styled.div`
  ${({ theme }) => `
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 18px;

    ${theme.breakpoints.up('sm')} {
      margin-top: 34px;
      margin-right: 36px;
      margin-left: 36px;
    }
  `}
`;

export default StyledAppWrapper;
