import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useNavigate, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Themes from './Themes';
import Counter from './Counter';
import PricingTable from './PricingTable';

const Header = styled.div`
  position: relative;
  display: flex;
  background-image: linear-gradient(#26c6da, #26c6da66);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    0 calc(100% - 5vw)
  );
  font-size: 28px;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
`;

const HeaderContainer = styled.div`
  margin-top: -58px;
  text-align: center;
  padding: 24px;
`;

const SiteName = styled(Typography)`
  ${({ theme }) => `
    font-size: 56px;
    padding-bottom: 12px;

    ${theme.breakpoints.up('sm')} {
      font-size: 72px;
      padding: 0;
    }
  `}
`;

const Section = styled.div`
  ${({ theme }) => `
    padding: 45px;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.text.primary};
    min-height: 500px;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      padding: 75px 125px;
      flex-direction: row;
    }
  `}
`;

const ArrowSection = styled(Section)`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    clear: both;
    z-index: 10;
  }
`;

const Footer = styled.div`
  ${({ theme }) => `
    background-color: #37474f;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fafafa;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
    }
  `}
`;

const SectionWrapper = styled.div`
  display: block;
`;

const GreySection = styled(ArrowSection)`
  background-color: #26c6da66;
  flex-direction: column;

  &::after {
    border-top: 30px solid #26c6da66;
  }

  &:last-child {
    &::after {
      border-top: none;
    }
  }

  p:first-child {
    padding-bottom: 45px;
  }
`;

const WhiteSection = styled(ArrowSection)`
  background-color: #fafafa;
  flex-direction: column;

  &::after {
    border-top: 30px solid #fafafa;
  }
`;

const Image = styled.img`
  ${({ theme }) => `
    border: 1px solid ${theme.palette.text.secondary};
    width: 100%;
    max-width: 450px;
    margin: 25px 0;

    ${theme.breakpoints.up('sm')} {
      margin: 25px;
    }
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
    display: flex;
    margin-bottom: 8px;
    color: ${theme.palette.primary.contrastText};
    text-decoration: none;
    cursor: pointer;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 0;
      margin-left: 12px;
    }
  `}
`;

const Wrapper = styled.div`
  margin: 45px 0;
`;

const CallToActionButton = styled(Button)`
  font-size: 1rem;
`;

const Description = styled(Typography)`
  padding-bottom: 25px;
`;

const Landing = () => {
  const { questions_count, sentences_count, sentence_conjugations_count } = JSON.parse(window.sessionStorage.getItem('stats')).attributes;
  const navigate = useNavigate();

  const handlePracticeClick = () => navigate('/dashboard');
  const handleSignUpClick = () => navigate('/sign-up');

  return (
    <>
      <Header>
        <HeaderContainer>
          <SiteName variant="h1">
            Spanish In A Minute
          </SiteName>
          <Typography variant="h5">
            Level up your Spanish in no time at all
          </Typography>
          <Button
            style={{ top: '80px', fontSize: '1.1rem' }}
            variant="contained"
            color="primary"
            onClick={handlePracticeClick}
          >
            Start Practing Now!
          </Button>
        </HeaderContainer>
      </Header>

      <SectionWrapper>
        <WhiteSection>
          <Grid container item lg={8}>
            <Counter count={sentence_conjugations_count} name="Conjugations" />
            <Counter count={sentences_count} name="Sentences" />
            <Counter count={questions_count} name="Questions" />
          </Grid>
        </WhiteSection>
        <GreySection>
          <Grid container item lg={8}>
            <Description variant="h6">
              Highly customizable exercises allowing you to practice the Spanish grammar topics
              that prove the most difficult for English learners, as well as the ability to practice verb conjugations in context.
            </Description>
            <Themes />
          </Grid>
        </GreySection>

        <WhiteSection>
          <Grid container item lg={8} justifyContent="center">
            <Typography variant="h6">
              Practice exactly the subjects that you need to focus on,
              with the ability to customize your settings to your reflect your exact level and needs.
            </Typography>
            <PricingTable />
          </Grid>
        </WhiteSection>

        <GreySection>
          <Grid container item lg={8}>
            <Typography variant="h6">
              Once you create an account, you can fine tune your settings allowing you to
              only practice sentences with verb tenses that you are comfortable with, as well as customize
              your conjugation questions to focus on specific verb tenses and verb types.
            </Typography>
          </Grid>
          <Wrapper>
            <Image src="/verb_cloze.png" />
          </Wrapper>
          <CallToActionButton
            variant="contained"
            color="primary"
            onClick={handleSignUpClick}
            style={{ bottom: '12px' }}
          >
            Sign Up To Customize Your Experience Now!
          </CallToActionButton>
        </GreySection>
      </SectionWrapper>
      <Footer>
        <Typography variant="body1">
          <StyledLink as="a" to="mailto:spanishinaminute@gmail.com?subject=Hi!">Contact</StyledLink>
        </Typography>
        <Typography variant="body1">
          <StyledLink data-cy="changelog-link" to="/changelog">Changelog</StyledLink>
        </Typography>
        <Typography variant="body1">
          <StyledLink data-cy="terms-link" to="/terms">Terms</StyledLink>
        </Typography>
        <Typography variant="body1">
          <StyledLink data-cy="privacy-link" to="/privacy">Privacy</StyledLink>
        </Typography>
      </Footer>
    </>
  );
};

export default Landing;
