import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StaticAppWrapper from '@/components/Static/StaticAppWrapper';
import StatsList from './StatsList';

const DashboardBody = styled(StaticAppWrapper)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    ${theme.breakpoints.up('sm')} {
      padding-top: 10px;
    }

    p {
      text-align: center;
    }
  `}
`;

const StyledButton = styled(Button)`
  margin: 45px 0;
`;

const SignUpText = styled(Typography)`
  padding-top: 45px;
`;

const emptyStats = { totalSeen: 0 };
const emptyStatsList = {
  paraPor: emptyStats,
  subjunctiveIndicative: emptyStats,
  verbTakesPreposition: emptyStats,
  verbCloze: emptyStats,
  directIndirectObject: emptyStats,
};

const UnAuthenticatedDashboard = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => navigate('/sign-up');

  return (
    <DashboardBody>
      <StatsList disabled stats={emptyStatsList} />
      <div>
        <StyledButton
          data-cy="call-to-action"
          variant="contained"
          color="primary"
          onClick={handleSignUpClick}
        >
          Sign Up Now!
        </StyledButton>
      </div>
      <Tabs
        value="history"
        indicatorColor="primary"
        textColor="primary"
        centered
        data-cy="tabs"
      >
        <Tab disabled value="history" label="History" data-cy="history-tab" />
        <Tab disabled value="bookmarks" label="Bookmarks" data-cy="bookmarks-tab" />
      </Tabs>
      <SignUpText data-cy="need-to-be-logged-in">You need to be signed in to view history and bookmarks.</SignUpText>
    </DashboardBody>
  );
};

export default UnAuthenticatedDashboard;
