/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createRoot } from 'react-dom/client';

import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import ReactGA from 'react-ga4';
import AuthProvider from '@/containers/AuthProvider';
import SnackbarProvider from '@/containers/SnackbarProvider';

import CookieConsent, { cookieConsentCookieExists } from '@/containers/CookieConsent';
import { readAuthToken } from '@/helpers/api_helper';

import App from '@/containers/App';

import ScrollToTop from '@/containers/ScrollToTop';
import appTheme from '@/appTheme';

// If the user consented to cookies, or the auth token is set, initialize google analytics
const user = readAuthToken();

if (cookieConsentCookieExists() || !_isEmpty(user)) {
  if (process.env.VITE_GOOGLE_ANALYTICS_KEY) {
    ReactGA.initialize(process.env.VITE_GOOGLE_ANALYTICS_KEY);

    if (!_isEmpty(user)) {
      ReactGA.set({ user_id: user.id });
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('appContainer');
  const root = createRoot(container);

  root.render(
    <div style={{ height: '100%' }}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <SnackbarProvider>
              <BrowserRouter>
                <AuthProvider>
                  <ScrollToTop />

                  <App />
                  <CookieConsent />
                </AuthProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </div>,
  );
});
