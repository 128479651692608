import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TourProvider, components, useTour } from '@reactour/tour';

import { AuthContext } from '@/containers/AuthProvider';

import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

const Main = styled.main`
  background-color: white;
`;

const stepTitles = [
  'Welcome!',
  'Grammar Exercises',
  'Conjugation Practice',
  'Account Settings',
  'Profile Settings',
  'Verb Tense Settings',
];

const TitleBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
`;

const CustomComponent = ({
  currentStep, steps, setIsOpen, ...rest
}) => {
  const totalSteps = steps.length;
  const isLastStep = currentStep === totalSteps - 1;
  const { content } = steps[currentStep];
  const title = stepTitles[currentStep];

  return (
    <Main>
      <TitleBlock>
        <Typography variant="h6">{title}</Typography>
      </TitleBlock>
      {/* Check if the step.content is a function or a string */}
      {typeof content === 'function'
        ? content({ currentStep, steps, setIsOpen, ...rest })
        : content}
      {!isLastStep ? (
        <components.Navigation
          hideDots
          setIsOpen={setIsOpen}
          currentStep={currentStep}
          steps={steps}
          {...rest}
        />
      ) : (
        <Grid container justifyContent="center">
          <StyledButton
            variant="contained"
            color="primary"
            data-cy="got-it-button"
            onClick={() => setIsOpen(false)}
          >
            Got it!
          </StyledButton>
        </Grid>
      )}
    </Main>
  );
};

export function tourCookieExists() {
  return document.cookie.match(/^(.*;)?\s*FinishedTour\s*=\s*[^;]+(.*)?$/);
}

const steps = [
  {
    title: 'Welcome!',
    content: 'Continue to take a quick tour of the application.',
    position: 'center',
    padding: 0,
    styles: {
      popover: (base) => ({
        ...base,
        transition: 'none',
      }),
    },
  },
  {
    selector: '[data-cy="grammar-practice"]',
    content: 'Here you will find exercises to practice different Spanish grammar concepts.',
    position: 'top',
  },
  {
    selector: '[data-cy="conjugation-practice"]',
    content: 'Here you will find customizable fill in the blank exercises for practicing verb conjugations.',
    position: 'right',
  },
  {
    selector: '#account-icon',
    content: 'You can configure your account settings by clicking here.',
    position: 'bottom',
  },
  {
    selector: '[data-cy="profile-tab"]',
    content: 'On the profile tab, you can control your user information and preferences for accents and vostros/usted.',
  },
  {
    selector: '[data-cy="tenses-tab"]',
    content: "On the verb tenses tab you can fine tune which verb tenses you're comfortable seeing throughout the site.",
  },
];

const TourStart = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isAuthenticated, user } = React.useContext(AuthContext);
  const isDashboard = isAuthenticated && pathname === '/';
  const { setIsOpen } = useTour();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userUnpracticed = user.last_question_type_seen === null;
  const shouldTour = !isMobile && !tourCookieExists() && userUnpracticed;

  useEffect(() => {
    if (shouldTour && isDashboard) {
      setIsOpen(true);
    }
  }, [pathname]);

  return null;
};

const Tour = ({ children }) => {
  const navigate = useNavigate();

  const handleTourClose = () => {
    document.cookie = 'FinishedTour=true; path=/; max-age=31536000';
  };
  const [step, setStep] = useState(0);

  const setCurrentStep = (currentStep) => {
    switch (currentStep) {
      case 4:
        navigate('/profile');
        break;
      case 5:
        navigate('/profile/verb-tenses');
        break;
      default:
        navigate('/dashboard');
        break;
    }
    setStep(currentStep);
  };

  return (
    <TourProvider
      showNavigation={step !== steps.length - 1}
      currentStep={step}
      ContentComponent={CustomComponent}
      setCurrentStep={setCurrentStep}
      beforeClose={handleTourClose}
      steps={steps}
      startAt={0}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: 4,
        }),
      }}
    >
      <TourStart />
      {children}
    </TourProvider>
  );
};

export default Tour;
