import React, { useState } from 'react';

import { NavBar, SideBar } from '@/components/Layout';
import { Snackbar } from '@/components/Shared';

import { AuthContext } from '@/containers/AuthProvider';

import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import RouterConfig from '@/containers/RouterConfig';
import { PrismicProvider } from '@prismicio/react';
import { client } from '@/helpers/prismic_client';

import Tour from './Tour';

function StyledDiv({ isLanding, ...props }) {
  return <div {...props} />;
}

const StyledApp = styled(StyledDiv, { label: 'App' })`
  ${({ theme, isLanding }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;

    ${theme.breakpoints.up('md')} {
      margin-left: ${isLanding ? 0 : 250}px;
    }
  `}
`;

const App = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated } = React.useContext(AuthContext);
  const { pathname } = useLocation();

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isLanding = !isAuthenticated && pathname === '/';

  return (
    <Tour>
      <Snackbar />
      <StyledApp isLanding={isLanding}>
        <Helmet>
          <title>SpanishInAMinute - Level up your Spanish in no time at all</title>
          <meta name="description" content="Online customizable Spanish grammar workbook and exercises" />
          <meta name="keywords" content="Spanish, Grammar, FlashCard, Quiz, Workbook, Practice" />
        </Helmet>
        <SideBar
          isLanding={isLanding}
          open={drawerOpen}
          openDrawer={openDrawer}
          closeDrawer={closeDrawer}
        />
        <NavBar toggleDrawer={toggleDrawer} />

        <PrismicProvider client={client}>
          <RouterConfig />
        </PrismicProvider>
      </StyledApp>
    </Tour>
  );
};

export default App;
