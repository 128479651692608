import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { DirectIndirectObjectCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet';

const DirectIndirectObjectPracticePage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Direct/Indirect Object</title>
        <meta name="description" content="Direct/Indirect Object Grammar Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Direct Object, Indirect Object, FlashCard" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/direct-indirect-object"
        CardClass={DirectIndirectObjectCard}
      />
    </>
  )
}

export default DirectIndirectObjectPracticePage;
