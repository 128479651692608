import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { PreteriteImperfectCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet';

const PreteriteImperfectPage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Preterite/Imperfect</title>
        <meta name="description" content="Preterite/Imperfect Grammar Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Preterite, Imperfect, FlashCard" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/preterite-imperfect"
        CardClass={PreteriteImperfectCard} />
    </>
  )
}

export default PreteriteImperfectPage;
