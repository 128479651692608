import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { VerbPrepositionCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet';

const VerbPrepositionPage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Verbs with Prepositions</title>
        <meta name="description" content="Spanish Verb Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Preposition, Verb, FlashCard" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/verb-preposition"
        CardClass={VerbPrepositionCard} />
    </>
  )
}

export default VerbPrepositionPage;