import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { SubjunctiveIndicativeCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet';

const SubjunctiveIndicativePage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Subjunctive/Indicative</title>
        <meta name="description" content="Subjunctive/Indicative Grammar Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Subjunctive, Indicative, FlashCard" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/subjunctive-indicative"
        CardClass={SubjunctiveIndicativeCard} />
    </>
  )
}

export default SubjunctiveIndicativePage;
