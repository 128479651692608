import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import styled from 'styled-components';
import StaticAppWrapper from './StaticAppWrapper';

const PageTitle = styled(Typography)`
  text-align: center;
  padding-bottom: 28px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const PageDescription = styled.div`
  margin-bottom: 20px;
`;

const Tos = () => (
  <StaticAppWrapper>
    <PageTitle variant="h4">Terms Of Service</PageTitle>
    <PageDescription variant="body2">
      <Typography variant="body2">
        By accessing and using SpanishInAMinute.com, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and SpanishInAMinute.com.
      </Typography>
      <Typography variant="body2">
        Under no circumstances shall the SpanishInAMinute.com team be liable for any direct, indirect, special, incidental or consequential damages arising out of the use, or the inability to use, the materials on this site, even if the SpanishInAMinute.com team or an authorized representative has been advised of the possibility of such damages.
      </Typography>
      <Typography variant="body2">
        SpanishInAMinute.com will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to revise the resources usage policy at any moment.
      </Typography>
    </PageDescription>

    <Section>
      <Typography variant="h5">Restrictions</Typography>
      <Typography variant="body2">
        You agree not to, and you will not permit others to:

        <ul>
          <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the app or make the platform available to any third party.</li>
          <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the app.</li>
          <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of SpanishInAMinute.com or its affiliates, partners, suppliers or the licensors of the app.</li>
        </ul>
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Changes To Our Terms & Conditions</Typography>
      <Typography variant="body2">
        You acknowledge and agree that SpanishInAMinute.com may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at SpanishInAMinute.com’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform SpanishInAMinute.com when you stop using the Service. You acknowledge and agree that if SpanishInAMinute.com disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.
      </Typography>
      <Typography variant="body2">
        If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the Terms & Conditions modification date below.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Modifications to Our app</Typography>
      <Typography variant="body2">
        SpanishInAMinute.com reserves the right to modify, suspend or discontinue, temporarily or permanently, the app or any service to which it connects, with or without notice and without liability to you.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Updates to Our app</Typography>
      <Typography variant="body2">
        SpanishInAMinute.com may from time to time provide enhancements or improvements to the features/ functionality of the app, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").
      </Typography>
      <Typography variant="body2">
        Updates may modify or delete certain features and/or functionalities of the app. You agree that SpanishInAMinute.com has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the app to you.
      </Typography>
      <Typography variant="body2">
        You further agree that all Updates will be (i) deemed to constitute an integral part of the app, and (ii) subject to the terms and conditions of this Agreement.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Third-Party Services</Typography>
      <Typography variant="body2">
        We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").
      </Typography>
      <Typography variant="body2">
        You acknowledge and agree that SpanishInAMinute.com shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. SpanishInAMinute.com does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
      </Typography>
      <Typography variant="body2">
        Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Term and Termination</Typography>
      <Typography variant="body2">
        This Agreement shall remain in effect until terminated by you or SpanishInAMinute.com.
      </Typography>
      <Typography variant="body2">
        SpanishInAMinute.com may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
      </Typography>
      <Typography variant="body2">
        This Agreement will terminate immediately, without prior notice from SpanishInAMinute.com, in the event that you fail to comply with any provision of this Agreement. You may also terminate this by contacting us to delete your account.
      </Typography>
      <Typography variant="body2">
        Upon termination of this Agreement, you shall cease all use of the app.
        Termination of this Agreement will not limit any of SpanishInAMinute.com's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Copyright Infringement Notice</Typography>
      <Typography variant="body2">
        If you are a copyright owner or such owner’s agent and believe any material on our app constitutes an infringement on your copyright, please contact us setting forth the following information:
        <ul>
          <li>(a) a physical or electronic signature of the copyright owner or a person authorized to act on his behalf;</li>
          <li>(b) identification of the material that is claimed to be infringing;</li>
          <li>(c) your contact information, including your address, telephone number, and an email;</li>
          <li>(d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners; and</li>
          <li>(e) the a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</li>
        </ul>
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">No Warranties</Typography>
      <Typography variant="body2">
        The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, SpanishInAMinute.com, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the app, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, SpanishInAMinute.com provides no warranty or undertaking, and makes no representation of any kind that the app will meet your requirements, achieve any intended results, be compatible or work with any other software, , systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
      </Typography>
      <Typography variant="body2">
        Without limiting the foregoing, neither SpanishInAMinute.com nor any SpanishInAMinute.com's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the app, or the information, content, and materials or products included thereon; (ii) that the app will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the app; or (iv) that the app, its servers, the content, or e-mails sent from or on behalf of SpanishInAMinute.com are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
      </Typography>
      <Typography variant="body2">
        Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Severability</Typography>
      <Typography variant="body2">
        If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
      </Typography>
      <Typography variant="body2">
        This Agreement, together with the Privacy Policy and any other legal notices published by SpanishInAMinute.com on the Services, shall constitute the entire agreement between you and SpanishInAMinute.com concerning the Services. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and SpanishInAMinute.com.'s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND SpanishInAMinute.com AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Amendments to this Agreement</Typography>
      <Typography variant="body2">
        SpanishInAMinute.com reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </Typography>
      <Typography variant="body2">
        By continuing to access or use our app after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use SpanishInAMinute.com.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Updates to Our Terms</Typography>
      <Typography variant="body2">
        We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Intellectual Property</Typography>
      <Typography variant="body2">
        The app and its entire contents, features and functionality (including but not limited to all information, text, images, audio, and the design, selection and arrangement thereof), are owned by SpanishInAMinute.com, its licensors or other providers of such material and are protected by Panama and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws, unless otherwise explicitly indicated. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of SpanishInAMinute.com, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.
      </Typography>
      <Typography variant="body2">
        SpanishInAMinute.com also makes use of Third Party datasets (namely
        {' '}
        <Link href="http://www.tatoeba.com">Tatoeba</Link>
        ). This content is clearly identified, and distributed under a
        {' '}
        <Link href="https://creativecommons.org/licenses/by/2.0/fr/">CC BY 2.0 FR</Link>
        {' '}
        license.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Submissions and Privacy</Typography>
      <Typography variant="body2">
        In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data or proposals, including ideas for new or improved products, services, features, technologies or promotions, you expressly agree that such submissions will automatically be treated as non-confidential and non-proprietary and will become the sole property of SpanishInAMinute.com without any compensation or credit to you whatsoever. SpanishInAMinute.com and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.
      </Typography>
    </Section>

    <Section>
      <Typography variant="h5">Contact Us</Typography>
      <Typography variant="body2">
        If you have any further questions about this policy, please reach out to us at
        {' '}
        <Link href="mailto:spanishinaminute@gmail.com">spanishinaminute@gmail.com</Link>
        , and we will respond to you as quickly as possible.
      </Typography>
    </Section>
  </StaticAppWrapper>
);

export default Tos;
