import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

const UserDataForm = ({ formData, setFormData }) => {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        disabled
        autoFocus
        value={formData.email || ''}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />
      {/*
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="first_name"
        label="First Name"
        name="email"
        autoComplete="first_name"
        autoFocus
        value={formData.first_name || ''}
        onChange={e => setFormData({...formData, first_name: e.target.value})}
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="last_name"
        label="Last Name"
        name="email"
        autoComplete="last_name"
        autoFocus
        value={formData.last_name || ''}
        onChange={e => setFormData({...formData, last_name: e.target.value})}
      /> */}

      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.include_vosotros}
            onChange={(e) => setFormData({ ...formData, include_vosotros: e.target.checked })}
            value="include_vosotros"
          />
        )}
        label="Include Vosotros"
      />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.include_usted}
            onChange={(e) => setFormData({ ...formData, include_usted: e.target.checked })}
            value="include_usted"
          />
        )}
        label="Include Usted"
      />
      <br />
      <FormControlLabel
        control={(
          <Checkbox
            color="secondary"
            checked={formData.ignore_accents}
            onChange={(e) => setFormData({ ...formData, ignore_accents: e.target.checked })}
            value="ignore_accents"
          />
        )}
        label="Ignore Missing Accents"
      />
    </>
  );
};

UserDataForm.propTypes = {
};

UserDataForm.defaultProps = {
  formData: {},
};

export default UserDataForm;
