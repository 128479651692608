import React from 'react';
import Grid from '@mui/material/Grid';
import { AuthContext } from '@/containers/AuthProvider';

import { axiosInstance } from '@/helpers/api_helper';
import { SignInForm } from '@/components/Session';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const { setUser } = React.useContext(AuthContext);
  const navigate = useNavigate();

  // Need to clear on navigate away
  const [errorMessage, setErrorMessage] = React.useState(window.sessionStorage.getItem('notifications') || '');

  const handleSignIn = (email, password, rememberMe) => {
    axiosInstance.post(
      '/api/v1/users/sign_in',
      { user: { password, email, remember_me: rememberMe } },
    ).then((response) => {
      setUser(response.data.data.attributes);
      return navigate('/');
    }, (error) => {
      const message = error.response.data?.errors?.[0]?.title || 'Something went wrong!';
      setErrorMessage(message);
    });
  };

  return (
    <Grid container justifyContent="center">
      <SignInForm handleSignIn={handleSignIn} errorMessage={errorMessage} />
    </Grid>
  );
};

export default SignIn;
